import React, { useEffect, useState, useContext } from 'react';
import { Blocks } from 'react-loader-spinner'
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import Flip from 'react-reveal/Flip';
import { useNavigate, Link } from 'react-router-dom';
import { resizeNavBar } from './../NavBarJs';
import MiContexto from '../../MiContexto'
import { useTranslation } from 'react-i18next';
import BuyS1 from './BuyS1';

import { FacebookEmbed } from 'react-social-media-embed';
import { InstagramEmbed } from 'react-social-media-embed';
import { TikTokEmbed } from 'react-social-media-embed';
import { XEmbed } from 'react-social-media-embed';


import {
    EmailIcon,
    FacebookIcon,
    RedditIcon,
    TelegramIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
} from "react-share";
import {
    FacebookShareCount,
    RedditShareCount,
} from "react-share";
import {
    EmailShareButton,
    FacebookShareButton,
    RedditShareButton,
    TelegramShareButton,
    TwitterShareButton,

} from "react-share";
import { is } from 'date-fns/locale';


export default function Buy(props) {

    const { t } = useTranslation();
    const { mobile } = props


    const navigate = useNavigate()

    const [isShared, setIsShared] = useState(false)
    const [showAds, setShowAds] = useState(false)




    const handleShare = (social) => {
        console.log("Compartido en " + social)
        setIsShared(true)

        if (social === "Facebook") {
            window.$('#fbModal').modal();
        }
        if (social === "Instagram") {
            window.$('#instaModal').modal();
        }
        if (social === "TikTok") {
            window.$('#tiktokModal').modal();
        }

    }

    const handleDesbloquearClick = () => {

        let aNumber=""
        if(mobile?.number)aNumber=mobile?.number

        alert("Debes compartir en al menos una red social para desbloquear el acceso al móvil " + aNumber)
        if (!isShared) return
        setShowAds(true)
    }

    if (showAds)
        return (<BuyS1 />)


    return (
        <div className="container mt-1 " style={{ paddingTop: "20px", maxWidth: "450px" }} onClick={() => resizeNavBar()}>
            <FacebookModalPrompt />
            <InstagramModalPrompt />
            <TikTokModalPrompt />



            <Fade>
                <i className='fa fa-warning text-danger fa-2x font-weight-bold' />
                <div className='text-danger text-uppercase'>Comparte para desbloquear</div>

                <div className='my-1 ' style={{ fontSize: "0.8em" }}>
                    Queremos viralizar nuestra plataforma y para ello necesitamos tu ayuda. Comparte en al menos una red social para desbloquear el acceso al móvil.
                </div>

                {/* <div className='mt-2 ' style={{ fontSize: "0.8em" }}>
                    Debes compartir en al menos una red social para desbloquear el acceso al móvil {mobile?.number}
                </div> */}
            </Fade>





            <div style={{marginTop:"50px"}}>
                SELECCIONE UNA:
            </div>

            <div className=' px-4 py-3 blueShadow' style={{ backgroundColor: "rgba(0,0,0,1)", borderRadius: "30px" }}>

                {/* <div>
                    <Flip delay={0}>
                        <div onClick={() => handleShare("Facebook")}>
                            <FacebookIcon size={120} round={true} />
                        </div>
                    </Flip>
                </div> */}

                <div>
                    <Flip delay={400} >
                        <div className='my-3 ' onClick={() => handleShare("Instagram")}>
                            <img src="./images/instaa.png" alt="imagen" style={{ width: "120px", height: "120px" }} />
                        </div>
                    </Flip>
                </div>

                <div>
                    <Flip delay={800}>
                        <div onClick={() => handleShare("TikTok")}>
                            <img src="./images/tiktok.png" alt="imagen" style={{ width: "120px", height: "120px" }} />
                        </div>
                    </Flip>

                </div>
            </div>



            <div className='my-4' style={{ fontSize: "0.6em" }}>
                <div className='mb-4'> Una vez compartido, haz click en el botón de abajo para desbloquear el acceso al móvil {mobile?.number}</div>

                {isShared ?

                    <button className='btn btn-danger btn-sm btn-block' onClick={handleDesbloquearClick}>Desbloquear <i className='fa fa-unlock-alt' /></button>
                    :
                    <button className='btn btn-danger btn-sm btn-block disabled' onClick={handleDesbloquearClick}>Desbloquear <i className='fa fa-unlock-alt' /></button>
                }
            </div>



        </div>
    )


}

export function FacebookModalPrompt() {
    const urlFacebook = "https://www.facebook.com/reel/870760331221833"

    const handleContinue = () => {
        window.open(urlFacebook, "_blank")
    }
    return (
        <div className="modal fade" id="fbModal" tabindex="-1" role="dialog" aria-labelledby="fbModal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header py-1">
                        <h5 className="modal-title text-dark" id="fbModal">INSTRUCCIONES:</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body text-dark text-left small">

                        <div className="ml-2">
                            <div className='text-center' >
                                <Bounce >
                                    <FacebookIcon size={100} round={true} />
                                </Bounce>
                                <div style={{ fontSize: "1.0em", textAlign: "center", marginLeft: "5px", color: "#0965fe" , fontWeight:"700", marginTop:"15px"}}>
                                    {/* <span style={{ color: "#0965fe" }}> INSTRUCCIONES:</span> */}
                                    <div><span style={{fontSize:"2.0em"}}> 1 </span> COMPARTE la publicación</div>
                                    <div><span style={{fontSize:"2.0em"}}> 2 </span> ETIQUETA a 5 personas</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                        {/* <button type="button" className="btn btn-primary" data-dismiss="modal" >Cerrar</button> */}
                        <FacebookShareButton
                            children={""}
                            url={urlFacebook}
                            onShareWindowClose={() => console.log("...")} >
                            <button type="button" className="btn btn-dark" data-dismiss="modal" >Continuar</button>
                        </FacebookShareButton>

                    </div>
                </div>
            </div>
        </div>
    )
}

function InstagramModalPrompt() {
    // const urlInstagram = "https://www.instagram.com/reel/C4qrP8GP1rR/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
    const urlInstagram = "https://www.instagram.com/catwatchful_es"
 
    const handleContinue = () => {
        window.open(urlInstagram, "_blank")
    }

    return (
        <div className="modal fade" id="instaModal" tabindex="-1" role="dialog" aria-labelledby="instaModal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header py-1 ">
                        <h5 className="modal-title text-dark" id="instaModal">INSTRUCCIONES:</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body text-dark  small px-0">

                        <div className="ml-2 ">
                            <div className='text-center'>
                                <Bounce  >
                                    <img src="./images/instaa.png" alt="imagen" style={{ width: "100px", height: "100px" }} />
                                </Bounce>

                                <div style={{ fontSize: "1.0em", textAlign: "center", marginLeft: "5px", color: "#c20088", fontWeight:"700", marginTop:"15px" }}>
                                    {/* <span style={{ color: "#c20088" }}> INSTRUCCIONES:</span> */}
                                    <div><span style={{fontSize:"2.0em"}}> 1 </span>SIGUENOS en Instagram</div>
                                    <div><span style={{fontSize:"2.0em"}}> 2 </span>COMENTA Y REACCIONA en 3 Reels</div>
                                    <div><span style={{fontSize:"2.0em"}}> 3 </span>COMPARTE al menos con 3 personas</div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                        {/* <button type="button" className="btn btn-primary" data-dismiss="modal" >Cerrar</button> */}
                        <button type="button" className="btn btn-dark" data-dismiss="modal" onClick={() => handleContinue()}>Continuar</button>

                    </div>
                </div>
            </div>
        </div>
    )


}

function TikTokModalPrompt() {
    const urlTikTok = "https://www.tiktok.com/@catwatchful.es/"


    const handleContinue = () => {
        window.open(urlTikTok, "_blank")
    }

    return (

        <div className="modal fade" id="tiktokModal" tabindex="-1" role="dialog" aria-labelledby="tiktokModal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header py-1">
                        <h5 className="modal-title text-dark" id="tiktokModal">INSTRUCCIONES:</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body text-dark text-left small">

                        <div className='text-center'>
                            <Bounce>
                                <div className='my-3 '>
                                    <img src="./images/tiktok.png" alt="imagen" style={{ width: "100px", height: "100px" }} />
                                </div>
                            </Bounce>
                            <div style={{ fontSize: "1.0em", textAlign: "center", marginLeft: "5px", color: "#ff2a57" , fontWeight:"700", marginTop:"15px" }}>
                                {/* <span style={{ color: "#00f1e7" }}> INSTRUCCIONES:</span> */}
                                <div><span style={{fontSize:"2.0em"}}> 1 </span> SIGUE nuestro canal</div>
                                <div><span style={{fontSize:"2.0em"}}> 2 </span> REACCIONA y COMENTA el último TikTok</div>
                                <div><span style={{fontSize:"2.0em"}}> 3 </span> COMPARTE el último TikTok</div>
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                        {/* <button type="button" className="btn btn-primary" data-dismiss="modal" >Cerrar</button> */}
                        <button type="button" className="btn btn-dark" data-dismiss="modal" onClick={() => handleContinue()}>Continuar</button>

                    </div>
                </div>
            </div>
        </div>
    )


}