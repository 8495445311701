import React, { useContext } from 'react';

import MiContexto from '../MiContexto'

import DemoDevice from './DemoDevice';
import BuyS1 from './BuyStrategies/BuyS1';
import BuyS2 from './BuyStrategies/BuyS2';
import BuyS3 from './BuyStrategies/BuyS3';

export default function Buy(props) {

    const { currentMobile, demoMode } = useContext(MiContexto)

    if (!demoMode) return <DemoDevice mobile={currentMobile} />

    return (
        // <BuyS1 mobile={currentMobile} />
        <BuyS2 mobile={currentMobile} />
    )


}




